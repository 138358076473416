<template>
    <v-footer height="auto" padless class="footer-stepbar">
        <v-container fluid class="py-0 footer">
            <v-row>
                <v-col cols="12" class="AXAdarkIndigo whiteDark--text">
                    <v-container fluid pa-0>
                        <v-row>
                            <v-col cols="12" class="py-0">
                                Messaggio pubblicitario con finalità promozionale. "Per Noi" è un prodotto di AXA MPS Vita S.p.A
                                società del Gruppo Assicurativo AXA.
                                La quotazione è indicativa e non vincolante, non costituisce né sostituisce un
                                preventivo e/o una proposta contrattuale in quanto mancante di alcuni elementi necessari
                                all’assunzione del rischio assicurativo. Per avere maggiori informazioni e un preventivo
                                su misura recati presso una filiale di Banca Monte dei Paschi di Siena. Prima della
                                sottoscrizione leggere il set informativo disponibile su <a
                                    href="https://www.axa-mps.it/assicurazione-vita-per-due-persone" target="_blank">www.axa-mps.it</a>
                                o nelle filiali
                                di Banca Monte dei Paschi di Siena.
                            </v-col>
                        </v-row>
                    </v-container>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" class="AXAazure whiteDark--text">
                    <v-container fluid pa-0>
                        <v-row>
                            <v-col cols="12" class="py-0">
                                &copy; {{ new Date().getFullYear() }} AXA MPS Assicurazioni Vita S.p.A | Partita IVA
                                GRUPPO IVA AXA ITALIA n. 10534960967 -
                                <a href="https://www.axa-mps.it/privacy" target="_blank" class="white--text">Privacy</a>
                                -
                                <a href="https://www.axa-mps.it/vita-chi-siamo" target="_blank" class="white--text">Note
                                    legali</a> -
                                <a href="https://www.axa-mps.it/utilizzo-dei-cookies" target="_blank"
                                   class="white--text">Cookies</a>
                            </v-col>
                            <v-col cols="12" class="py-0">
                                <a href="/accessibilita" target="_blank" class="white--text">Dichiarazione di accessibilità</a> -
                                <a href="https://www.mps.it/informazioni-intermediario.html" target="_blank"
                                   class="white--text">Dati sull'intermediario</a> -
                                <a href="https://www.mps.it/contatti/reclami-e-ricorsi.html" target="_blank"
                                   class="white--text">Reclami e ricorsi</a>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-col>
            </v-row>
        </v-container>
    </v-footer>
</template>

<script>
    export default {
        name: "footerStepMPS",
        data: () => ({
            //
        })
    };
</script>